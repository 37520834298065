import { HomePage } from "Pages";
import "./_app.scss";

function App() {
  return (
    <div className="loch-app">
      <HomePage />
    </div>
  );
}

export default App;
